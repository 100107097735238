@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("./MaterialIcons-Regular.woff2") format("woff2"),
    url("./MaterialIcons-Regular.woff") format("woff"),
    url("./MaterialIcons-Regular.ttf") format("truetype");
}

[class^="material-icons-"],
[class*=" material-icons-"] {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.material-icons-settings_applications:before {
  content: "\e8b9";
}
.material-icons-file-upload:before {
  content: "\e2c6";
}
.material-icons-file-download:before {
  content: "\e2c4";
}
.material-icons-dns:before {
  content: "\e875";
}

.material-icons-folder:before {
  content: "\e2c7";
}

.material-icons-assignment_ind:before {
  content: "\e85e";
}

.material-icons-assignment:before {
  content: "\e85d";
}

.material-icons-assessment:before {
  content: "\e85c";
}

.material-icons-home:before {
  content: "\e88a";
}

.material-icons-settings:before {
  content: "\e8b8";
}

.material-icons-exit_to_app:before {
  content: "\e879";
}

.material-icons-edit:before {
  content: "\e3c9";
}

.material-icons-delete:before {
  content: "\e872";
}

.material-icons-bookmark:before {
  content: "\e866";
}

.material-icons-description:before {
  content: "\e873";
}

.material-icons-filter_list:before {
  content: "\e152";
}

.material-icons-search:before {
  content: "\e8b6";
}

.material-icons-archive:before {
  content: "\e149";
}
.material-icons-keyboard-arrow-left:before {
  content: "\e314";
}
.material-icons-keyboard-arrow-right:before {
  content: "\e315";
}
.material-icons-more-vert:before {
  content: "\e5d4";
}
.material-icons-keyboard-arrow-down:before {
  content: "\e313";
}
.material-icons-keyboard-arrow-up:before {
  content: "\e316";
}
.material-icons-error_outline:before {
  content: "\e001";
}
.material-icons-information-outline::before{
  content: "\e88f";
}
