@font-face {
  font-family: 'icomoon';
  src: url('./icomoon.eot?m2gcgf');
  src: url('./icomoon.eot?m2gcgf#iefix') format('embedded-opentype'),
  url('./icomoon.ttf?m2gcgf') format('truetype'),
  url('./icomoon.woff?m2gcgf') format('woff'),
  url('./icomoon.svg?m2gcgf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-balance-insights:before {
  content: "\e90b";
}

.icon-align-left:before {
  content: "\e90c";
}

.icon-arrow:before {
  content: "\e90d";
}

.icon-edit:before {
  content: "\e90e";
}

.icon-close:before {
  content: "\e90f";
}

.icon-uniE910:before {
  content: "\e910";
}

.icon-uniE911:before {
  content: "\e911";
}

.icon-down-caret:before {
  content: "\e912";
}

.icon-downward-arrow:before {
  content: "\e913";
}

.icon-uniE914:before {
  content: "\e914";
}

.icon-elasticity-insights:before {
  content: "\e915";
}

.icon-left-angel:before {
  content: "\e917";
}

.icon-left-wide-arrow:before {
  content: "\e918";
}

.icon-list:before {
  content: "\e919";
}

.icon-loader:before {
  content: "\e91a";
}

.icon-uniE91B:before {
  content: "\e91b";
}

.icon-Constraint:before {
  content: "\e91c";
}

.icon-plus:before {
  content: "\e91d";
}

.icon-pointer:before {
  content: "\e91e";
}

.icon-portfolio-optimization:before {
  content: "\e91f";
}

.icon-pricing-insights:before {
  content: "\e920";
}

.icon-raising-arrow:before {
  content: "\e921";
}

.icon-right-angel:before {
  content: "\e922";
}

.icon-right-arrow:before {
  content: "\e923";
}

.icon-saved-list:before {
  content: "\e924";
}

.icon-settings:before {
  content: "\e925";
}

.icon-target:before {
  content: "\e926";
}

.icon-time:before {
  content: "\e927";
}

.icon-zoom:before {
  content: "\e928";
}

.icon-add:before {
  content: "\e929";
}

.icon-calender:before {
  content: "\e92a";
}

.icon-create:before {
  content: "\e92b";
}

.icon-delete:before {
  content: "\e92c";
}

.icon-download:before {
  content: "\e92d";
}

.icon-duplicate:before {
  content: "\e92e";
}

.icon-scoop:before {
  content: "\e92f";
}

.icon-Flow:before {
  content: "\e930";
}

.icon-logout:before {
  content: "\e931";
}

.icon-menu:before {
  content: "\e932";
}

.icon-move-file:before {
  content: "\e933";
}

.icon-objective:before {
  content: "\e934";
}

.icon-output:before {
  content: "\e935";
}

.icon-pin:before {
  content: "\e936";
}

.icon-product:before {
  content: "\e937";
}

.icon-save:before {
  content: "\e938";
}

.icon-Upload:before {
  content: "\e939";
}

.icon-search:before {
  content: "\e93a";
}

.icon-segment:before {
  content: "\e93b";
}
