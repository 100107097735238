@import "default_theme";

* {
  scrollbar-color: rgba(64, 64, 85, 0.25);
  scrollbar-width: 8px;
  padding-bottom: 0;
  scrollbar-gutter: stable;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent !important;
}

*::-webkit-scrollbar-track {
  background-color: transparent !important;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(64, 64, 85, 0.25);
  border-radius: 8px;
}
body::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: transparent !important;
}
::ng-deep body::-webkit-scrollbar-thumb {
  background-color: rgba(64, 64, 85, 0.25);

  border-radius: 8px;
}
::ng-deep body {
  scrollbar-color: rgba(64, 64, 85, 0.25);
}
