@font-face {
  font-family: 'icomoon2';
  src: url('./icomoon_new.eot?m2gcgf');
  src: url('./icomoon_new.eot?m2gcgf#iefix') format('embedded-opentype'),
  url('./icomoon_new.ttf?m2gcgf') format('truetype'),
  url('./icomoon_new.woff?m2gcgf') format('woff'),
  url('./icomoon_new.svg?m2gcgf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-new"], [class*=" icon-new"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon2' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-new-edit:before {
  content: "\e900";
}
