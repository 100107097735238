/*****Global Variables *******/
$header-bg: #fff;
$header-color: #3f0e27;
$footer-bg: #fff;
$footer-color: #3f0e27;
$copyrightLnk-color: #4d4d4d;
$navigation-bg: #e5eff7;

$body-fontsize: 16px;
$body-fontfamily: "SimonKucher-Regular", sans-serif; // Frutiger-Roman,
$bold-fontfamily: "SimonKucher-Bold", sans-serif; // Frutiger-Bold;
$body-bg: #fff;
$body-color: #000;

/**Colors**/
$primary-color: rgba(163, 20, 70, 1); // SKP color
$secondary-color: rgba(109, 110, 106, 1); // SKP color
$third-color: #404055;
$purble-color: #54529f;
$primary-color-transparency: rgb(163 20 70 / 40%);

$color-modal-header: #006b92;
$page-bg: #f6f6f7;
$color-finderType: #2381c8;
$color-white: #fff;
$color-black: #000;
$color-gray: #979797;
$color-midLightGrey: #d4d4d4;
$color-firstLightGrey: #d7d7d7;
$color-light-black: #333;
$color-orange: #ff6600;
$color-darkGrey: #808080;
$color-superDarkGrey: #6e6e6e;
$color-blackGray: #4d4d4d;
$color-superLightGrey: #f8f8f8;
$color-grayForItem: #5c5c6e;
$color-selectionForItem: #da1b5e;
$color-hoverForItem: #831038;
$color-hoverForDropdown: #ba1750;
$color-headForTable: #f2f2f3;
$color-headForDiscountTable: #cce1e9;
$color-bodyForTable: #191919;
$color-specificGrey: #cfcfd4;
$color-hoverInTable: #eeeef6;
$color-hoverInChips: rgba(186, 23, 80, 0.1);
$color-placeholder: #9696a2;
$color-textForHoverInChips: #831038;
$color-textForHoverInDefaultButton: #ba1750;
$color-lightGrey: #eee;
$color-darkblue: #002d67;
$color-lightBlue: rgba(191, 227, 255, 0.5);
$color-greyShadow: #e1e1e1;
$color-danger: #ff0000;
$color-danger-background: rgba(255, 0, 0, 0.55);
$color-disabled: #ccc;
$color-toggleBackground: #c5c5c5;
$color-btnSelected: #13a114;
$color-tabsTextColor: #f6f6f7;
$color-specificBlack: #39322b;
$color-success: #69ab55;
$color-greenStatus: #00a887;
$color-searchIcon: #a8a8a8;
$color-error: #721c24;
$btn-primary-bg: rgb(218, 27, 94);

$btn-primary-bg-hover: #ba1750;
$btn-primary-bg-active: #e9769e;
$btn-primary-bg-focus: #e1497e;
$btn-primary-bg-disabled:#F4BACE;
//rgb(217 28 92)
$btn-primary-color: #fff;
$error-color: #ff0000;
/******Font Paths*****/
$fontPath: "/assets/fonts/"; // "./"
$fontPathSkp: "../../assets/fonts/SKP/";
$iconPathSkp: "/assets/icons/";
$darkgray: #2c3e50;

//font
$main-font: "SimonKucher";
$font-size-large: 22px;
$font-size-upnormal: 18px;
$font-size-normal: 16px;
$font-size-small: 12px;
$font-size-tiny: 8px;
$font-light: 300;
$font-normal: 400;
$font-semibold: 500;
$font-bold: 700;
$font-extra-bold: 900;
// header
$header-font: $color-black;
$header-links: $primary-color;
$header-separator: rgba(151, 151, 151, 0.27); // #979797 + opacity 0.27
$header-height: 76px;

//sub header
$sub-header-title: $color-darkGrey;
$sub-header-active-link: $color-darkblue;
$sub-header-inactive-link: rgba(57, 85, 113, 0.46); // #395571 + opacity 0.46;
$sub-header-icon: rgba(57, 85, 113, 0.5); // #395571 + 0.5 opacity
$sub-header-height: 48px;

// Layout
$page-content-left-Indention: 5.5rem;
$page-content-right-Indention: 1.5rem;

// sidebar
$sidebar-hoverColor: #5e6273;
$sidebar-width: 4rem; // 80px;
$sidebar-fontsize: 3rem;
$icons-font-size: 1.5rem;
$sidebar-text-letterspacing: 0.13px;
$sidebar-bg: $third-color;
$sidebar-inactive: $color-white;
$sidebar-active-color: $third-color;
$sidebar-active-bg: $color-white;
$sidebar-sub-menu: $secondary-color;
$sb-sub-menu-font: $color-white;
$sidebar-sub-fontsize: 1rem;
$sidebar-submenu-text-letterspacing: 0.23px;
$sidebar-spacer: $color-gray;
// sidebar-list
$sidebar-list-item-width: 76px;
$sidebar-list-item-height: 76px;
$sidebar-sub-menu-width: 250px;
$sidebar-sub-menu-title-x-padding: 38px;
$sidebar-sub-menu-title-y-padding: 41px;
$sidebar-sub-menu-links-bottom-padding: 28px;
$sidebar-sub-menu-links-left-padding: 15px;
$sidebar-list-item-text-margin: 10px;
