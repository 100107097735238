@import './variables';
/**************** Custom fonts ********************/

//@font-face {
//  font-family: '';
//  src: url($fontPath + 'Frutiger-Roman.eot?#iefix') format('embedded-opentype'), url($fontPath + 'Frutiger-Roman.woff') format('woff'),
//  url($fontPath + 'Frutiger-Roman.ttf') format('truetype'), url($fontPath + 'Frutiger-Roman.svg#Frutiger-Roman') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'Frutiger-Bold';
//  src: url($fontPath + 'Frutiger-Bold.eot?#iefix') format('embedded-opentype'), url($fontPath + 'Frutiger-Bold.woff') format('woff'),
//  url($fontPath + 'Frutiger-Bold.ttf') format('truetype'), url($fontPath + 'Frutiger-Bold.svg#Frutiger-Bold') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'SimonKucher';
  font-style: normal;
  font-weight: $font-normal;
  src: url($fontPathSkp + 'SimonKucher-Regular.woff2') format('woff2'),
  url($fontPathSkp + 'SimonKucher-Regular.woff') format('woff'),
  url($fontPathSkp + 'SimonKucher-Regular.ttf')  format('truetype');
}
@font-face {
  font-family: 'SimonKucher';
  font-style: normal;
  font-weight: $font-bold;
  src: url($fontPathSkp + 'SimonKucher-Bold.woff2') format('woff2'),
  url($fontPathSkp + 'SimonKucher-Bold.woff') format('woff'),
  url($fontPathSkp + 'SimonKucher-Bold.ttf')  format('truetype');
}

@font-face {
  font-family: 'SimonKucher';
  font-style: italic;
  font-weight: $font-normal;
  src: url($fontPathSkp + 'SimonKucher-RegularItalic.woff2') format('woff2'),
  url($fontPathSkp + 'SimonKucher-RegularItalic.woff') format('woff'),
  url($fontPathSkp + 'SimonKucher-RegularItalic.ttf')  format('truetype');
}

@font-face {
  font-family: 'SimonKucher';
  font-style: italic;
  font-weight: $font-bold;
  src: url($fontPathSkp + 'SimonKucher-BoldItalic.woff2') format('woff2'),
  url($fontPathSkp + 'SimonKucher-BoldItalic.woff') format('woff'),
  url($fontPathSkp + 'SimonKucher-BoldItalic.ttf')  format('truetype');
}

/*****Icomoon Generated custom font******/

